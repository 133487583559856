// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/ui/curtain.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent;-webkit-touch-callout:none;background-color:#000;font-family:Arial,\"SFUIText-Light\";font-size:14px;height:100%;-webkit-hyphens:auto;hyphens:auto;line-height:1em;margin:0;padding:0;-webkit-user-select:none;-moz-user-select:none;user-select:none;width:100%}.curtain{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat;display:block;height:100%;position:fixed;width:50%}.left{float:left;left:0}.right{float:right;right:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
