import { render, staticRenderFns } from "./ingame.vue?vue&type=template&id=5adbbebf"
import script from "./ingame.vue?vue&type=script&lang=ts"
export * from "./ingame.vue?vue&type=script&lang=ts"
import style0 from "./ingame.vue?vue&type=style&index=0&id=5adbbebf&prod&lang=stylus"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/artifacts/BGFrontend/components/Layout/Loading.vue').default,ActivatorFullScreen: require('/artifacts/BGFrontend/components/Activator/ActivatorFullScreen.vue').default})
